const selectors = {
  el: '.js-games-filter',
  card: '.js-games-filter-card',
  button: '.js-games-filter-button',
  buttonResetFilter: '.js-games-filter-button-reset',
  emptyBlock: '.js-games-filter-empty'
}

const classes = {
  showElement: 'show',
  activeButton: 'active'
}

const el = document.querySelector(selectors.el);

if (el) {
  const cards = el.querySelectorAll(selectors.card);
  const buttons = el.querySelectorAll(selectors.button);
  const buttonResetFilter = el.querySelector(selectors.buttonResetFilter);
  const emptyBlock = el.querySelector(selectors.emptyBlock);
  const filters = [];

  // Функция для фильтрации карточек
  const filterElementsByTags = () => {
    const activeButtons = el.querySelectorAll(`.${classes.activeButton}`);

    // Если нет активных кнопок, показываем все карточки
    if (activeButtons.length === 0) {
      resetFilter();
      return;
    }

    const activeTags = Array.from(activeButtons).map(button => button.getAttribute('data-tag'));

    cards.forEach((card) => {
      const cardTags =
        card.getAttribute('data-tags')
        .replace(/\[|\]|"|'/g, '') // Убираем квадратные скобки, кавычки (двойные и одинарные)
        .split(',') // Разбиваем строку на массив

      // Показываем карточку, если хоть один из активных тегов совпадает
      if (activeTags.every(tag => cardTags.includes(tag))) {
        card.classList.add(classes.showElement);
      } else {
        card.classList.remove(classes.showElement);
      }

    });
  }

  const showDataEmptyFilters = () => {
    const isEmpty = Array.from(cards).every(card => !card.classList.contains(classes.showElement))

    emptyBlock.classList.toggle(classes.showElement, isEmpty)
  }

  // Функция для активации кнопки
  const toggleButton = (button) => {
    button.classList.toggle(classes.activeButton); // Активируем нужную кнопку
  }

  // Добавляем обработчики кликов на кнопки
  buttons.forEach((button) => {
    button.addEventListener('click', () => {
      toggleButton(button); // Делаем кнопку активной\неактивной
      filterElementsByTags(); // Применяем фильтрацию по тегу
      showDataEmptyFilters();
      // }
    });
  });

  const resetFilter = () => {
    cards.forEach((card) => card.classList.add(classes.showElement));
    buttons.forEach(button => button.classList.remove(classes.activeButton))
    showDataEmptyFilters();
  }

  // Фильтрация при загрузке страницы
  filterElementsByTags();

  buttonResetFilter.addEventListener('click', resetFilter)
}
