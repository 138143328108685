// import Swiper JS
// import Swiper from "swiper";

// import Swiper bundle with all modules installed
import Swiper from "swiper";
import { Mousewheel, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";

// import styles bundle

const swiper = new Swiper(".js-swiper-feedback", {
  //Optional parameters
  // direction: "horizontal",
  slidesPerView: 3,
  // centeredSlides: true,
  spaceBetween: 50,
  mousewheel: true,
  keyboard: {
    enabled: true,
  },
  breakpoints: {
    375: {
      slidesPerView: 1,
      spaceBetween: 20,
      centeredSlides: true,
    },
    768: {
      slidesPerView: 1,
      spaceBetween: 40,
      centeredSlides: true,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 50,
    },
  },
  modules: [Mousewheel, Navigation],

  navigation: {
    nextEl: ".js-feedback-swiper-button-next",
    prevEl: ".js-feedback-swiper-button-prev",
  },
  // And if we need scrollbar
  // scrollbar: {
  //   el: ".swiper-scrollbar",
  // },
});
