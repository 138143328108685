const myBar = document.getElementById("myBar");

if (myBar) {
  function scrollIndicator() {
    var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    var height =
    document.documentElement.scrollHeight -
    document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    myBar.style.width = scrolled + "%";
  }
  document.addEventListener("scroll", scrollIndicator);
}
